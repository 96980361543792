(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var replaceModalContents = function replaceModalContents(card, whatModal) {
  // GET MODAL FIELDS
  // shared in all modal types
  var $modal = document.getElementById(whatModal);
  var $modalName = $modal.querySelector('.modal-name');
  var $modalContent = $modal.querySelector('.modal--custom__content__inner');
  var $modalImg = null,
      $modalEmail = null,
      $modalGrade = null,
      $modalSpecialty = null,
      $modalPhone = null,
      $modalPosition = null,
      $modalTerm = null,
      $modalIcon = null,
      $modalContact = null,
      $modalImages = null,
      $modalButtons = null; // staff & director shared variables

  if (whatModal === 'modal-staff' || whatModal === 'modal-director') {
    $modalImg = $modal.querySelector('.modal-avatar');
    $modalEmail = $modal.querySelector('.modal-email');
  } // staff variables


  if (whatModal === 'modal-staff') {
    $modalGrade = $modal.querySelector('.modal-grade');
    $modalSpecialty = $modal.querySelector('.modal-specialty');
    $modalPhone = $modal.querySelector('.modal-phone');
  } // activities variables


  if (whatModal === 'modal-activity') {
    $modalEmail = $modal.querySelector('.modal-email');
    $modalContact = $modal.querySelector('.modal-contact');
  } // directors variables


  if (whatModal === 'modal-director') {
    $modalPosition = $modal.querySelector('.modal-position');
    $modalTerm = $modal.querySelector('.modal-term');
  } // PTO officers variables


  if (whatModal === 'modal-officer') {
    $modalImg = $modal.querySelector('.modal-avatar');
    $modalPosition = $modal.querySelector('.modal-position');
  }

  ; // Career paths modal (images and buttons)

  if (whatModal === 'modal-path' || whatModal === 'modal-activity') {
    var $modalImagesLinks, $modalButtonsData, $alreadyHasImages, $alreadyHasButtons; // IMAGES ----------------
    // if images already in modal, remove them firstly

    if ($alreadyHasImages = $modal.querySelector('.modal-carousel-wrapper')) {
      $alreadyHasImages.parentNode.removeChild($alreadyHasImages);
    }

    if ($modalImagesLinks = card.querySelector('.icon-card__content__images')) {
      // create slider with pagination wrapper
      var $wholeSlider = document.createElement('div');
      $wholeSlider.classList.add('modal-carousel-wrapper'); // create images wrapper (to be used with carousel if activated)

      var $modalImagesWrapper = document.createElement('div');
      $modalImagesWrapper.className = 'modal-images-swiper swiper-container carousel'; // TODO: rename partners
      // create images wrapper div

      $modalImages = document.createElement('div');
      $modalImages.className = 'swiper-wrapper modal--custom__content--imgs modal__slides';

      _toConsumableArray($modalImagesLinks.querySelectorAll('span')).forEach(function ($modalImgLink) {
        var $modal_img = document.createElement('div');
        $modal_img.className = 'swiper-slide modal__slides__slide';
        $modal_img.style.backgroundImage = "url(".concat($modalImgLink.getAttribute('data-img-src'), ")");
        $modalImages.appendChild($modal_img);
      });

      $modalImagesWrapper.appendChild($modalImages);
      $wholeSlider.appendChild($modalImagesWrapper); // insert images into modal

      $modalContent.parentNode.insertBefore($wholeSlider, $modalContent.previousSibling);
      var MODAL_IMAGES_MORE_THAN_TWO = $modalImages.querySelectorAll('.modal__slides__slide').length > 2;

      if (MODAL_IMAGES_MORE_THAN_TWO) {
        // create pagination for slider
        var $modal_images_pagination = document.createElement('div');
        $modal_images_pagination.className = 'swiper-pagination swiper-pagination-modal'; // insert pagination into modal

        $modalImagesWrapper.parentNode.insertBefore($modal_images_pagination, $modalImagesWrapper.nextSibling); // init carousel if there are more than 2 images

        var modal_swiper = new Swiper('.modal-images-swiper', {
          slidesPerView: 4,
          spaceBetween: 30,
          slidesPerGroup: 4,
          loop: false,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination-modal',
            clickable: true
          },
          breakpoints: {
            991: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            575: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            470: {
              slidesPerView: 1,
              slidesPerGroup: 1
            }
          }
        });
      }
    } // END IF IMAGES
    // BUTTONS ----------------
    // if BUTTONS already in modal, remove them firstly


    if ($alreadyHasButtons = $modal.querySelector('.modal--custom__content--btns')) {
      $alreadyHasButtons.parentNode.removeChild($alreadyHasButtons);
    }

    if ($modalButtonsData = card.querySelector('.icon-card__content__btns')) {
      // create buttons wrapper div
      $modalButtons = document.createElement('div');
      $modalButtons.classList.add('modal--custom__content--btns');

      _toConsumableArray($modalButtonsData.querySelectorAll('span')).forEach(function ($modalBtnSource) {
        var $modal_btn = document.createElement('a');
        $modal_btn.setAttribute('href', $modalBtnSource.getAttribute('data-btn-url'));
        $modal_btn.setAttribute('target', '_blank');
        $modal_btn.textContent = $modalBtnSource.getAttribute('data-btn-txt');
        $modal_btn.className = 'btn btn--brand';
        $modalButtons.appendChild($modal_btn);
      }); // insert buttons into modal


      $modalContent.parentNode.insertBefore($modalButtons, $modalContent.nextSibling);
    } // END IF BUTTONS

  } // if modal == path || modal === 'modal-activity'
  // paths variables


  if (whatModal === 'modal-path' || whatModal === 'modal-activity') {
    $modalIcon = $modal.querySelector('.modal-icon');
  }

  ; // GET DATA FOR MODAL FIELDS

  var modalData = {};
  modalData.name = card.querySelector('.card-title').textContent;
  modalData.content = card.querySelector('.card-content').innerHTML;

  if (whatModal === 'modal-staff' || whatModal === 'modal-director') {
    modalData.avatar = card.dataset.avatarImg;
    modalData.email = card.dataset.email;
  }

  ;

  if (whatModal === 'modal-staff') {
    modalData.grade = card.dataset.staffGradeText;
    modalData.specialty = card.dataset.staffSpecialty;
    modalData.phone = card.dataset.staffPhone;
  }

  ;

  if (whatModal === 'modal-activity') {
    modalData.email = card.dataset.email;
    modalData.contact = card.dataset.activityContact;
  }

  ;

  if (whatModal === 'modal-director') {
    modalData.position = card.dataset.directorPosition;
    modalData.term = card.dataset.directorTerm;
  }

  ;

  if (whatModal === 'modal-officer') {
    modalData.avatar = card.dataset.avatarImg;
    modalData.position = card.dataset.officerPosition;
  }

  ;

  if (whatModal === 'modal-path' || whatModal === 'modal-activity') {
    modalData.icon = card.querySelector('.icon-card__icon').getAttribute('src');
  }

  ; // REPLACE CONTENTS IN MODAL

  $modalName.textContent = modalData.name;
  $modalContent.innerHTML = modalData.content;

  if (whatModal === 'modal-staff' || whatModal === 'modal-director') {
    $modalImg.style.backgroundImage = "url('".concat(modalData.avatar, "')");
    $modalEmail.textContent = modalData.email;
    $modalEmail.parentElement.setAttribute('href', "mailto:".concat(modalData.email));
  }

  ;

  if (whatModal === 'modal-staff') {
    $modalGrade.textContent = modalData.grade;
    $modalSpecialty.textContent = modalData.specialty;
    $modalPhone.textContent = modalData.phone;
    $modalPhone.parentElement.setAttribute('href', "tel:".concat(modalData.phone));
  }

  ;

  if (whatModal === 'modal-activity') {
    $modalEmail.textContent = modalData.email;
    $modalContact.innerHTML = modalData.contact;
    $modalEmail.setAttribute('href', "mailto:".concat(modalData.email));
  }

  ;

  if (whatModal === 'modal-director') {
    $modalPosition.textContent = modalData.position;
    $modalTerm.textContent = modalData.term;
  }

  ;

  if (whatModal === 'modal-officer') {
    $modalImg.style.backgroundImage = "url('".concat(modalData.avatar, "')");
    $modalPosition.textContent = modalData.position;
  }

  ;

  if (whatModal === 'modal-path' || whatModal === 'modal-activity') {
    $modalIcon.setAttribute('src', modalData.icon);
  }

  ;
}; // replaceModalContents
// open modal function


var openModal = function openModal(modalId) {
  var $modal = document.getElementById(modalId);
  document.body.classList.add('modal--custom-open');
  $modal.classList.add('modal--custom-open');
};

document.querySelectorAll('.modal--custom-close').forEach(function (modalCLose) {
  modalCLose.addEventListener('click', function () {
    var $parentModal = document.querySelector('.modal--custom.modal--custom-open');
    document.body.classList.remove('modal--custom-open');
    $parentModal.classList.remove('modal--custom-open');
  });
});

var staffFilterAndSort = function staffFilterAndSort() {
  var $CARDS_WRAPPER = document.querySelector('.js-row--grade-cards');
  var $GRADE_TITLE = document.querySelector('.staff-filter__selected_grade'); // array of all possible grades

  var $GRADE_ELEMENTS = _toConsumableArray(document.querySelectorAll('.staff-filter-option')); // grade options


  var GRADES = $GRADE_ELEMENTS.map(function (grade) {
    return grade.getAttribute('data-href');
  });
  var $ALL_GRADE_CARDS = document.querySelectorAll('.grade-card-col'); // stores matching cards per each option as param

  var GRADE_CARDS_OBJECT = {}; // sort related

  var $STAFF_SORT_TOGGLE = document.getElementById('staff-sort-toggle');
  var $STAFF_SORT_OPTIONS = document.querySelectorAll('.staff-sort--option');
  var currentlyActiveSortOption = 'last-a-z'; // build grade_cards_object

  GRADES.forEach(function (grade) {
    GRADE_CARDS_OBJECT["".concat(grade)] = _toConsumableArray($ALL_GRADE_CARDS).filter(function (card) {
      return card.getAttribute('data-staff-grade') === grade;
    });
  }); // initial grade based on whether the page is accessed with or without a query string (link from other page)

  var INITIAL_GRADE = location.search.indexOf('?grade') >= 0 ? location.search.split('=')[1] : GRADES[0]; // initially set only first grade in array as active
  // filters

  var INITIALLY_ACTIVE_GRADE = $GRADE_ELEMENTS.find(function (grade) {
    return grade.getAttribute('data-href') === INITIAL_GRADE;
  });
  INITIALLY_ACTIVE_GRADE.classList.add('js-active');
  $GRADE_TITLE.textContent = INITIALLY_ACTIVE_GRADE.dataset.href.replace('-', ' '); // cards

  $CARDS_WRAPPER.innerHTML = '';
  GRADE_CARDS_OBJECT[INITIAL_GRADE].forEach(function ($gradeItem) {
    $CARDS_WRAPPER.appendChild($gradeItem); // open modals on click

    $gradeItem.addEventListener('click', function (e) {
      e.preventDefault();
      replaceModalContents($gradeItem, 'modal-staff');
      openModal('modal-staff');
    });
  }); // handle filtering on click

  $GRADE_ELEMENTS.forEach(function (filterOption) {
    filterOption.addEventListener('click', function () {
      $GRADE_ELEMENTS.forEach(function ($gradeEl) {
        return $gradeEl.classList.remove('js-active');
      });
      $CARDS_WRAPPER.innerHTML = '';
      filterOption.classList.add('js-active'); // TODO: add eventHandlers here for modals

      GRADE_CARDS_OBJECT[filterOption.getAttribute('data-href')].forEach(function ($gradeItem) {
        $CARDS_WRAPPER.appendChild($gradeItem);
      });
      staffSort(currentlyActiveSortOption); // problem is in this fn

      $GRADE_TITLE.textContent = filterOption.dataset.href.replace('-', ' ');
    });
  }); // sorting function

  var staffSort = function staffSort(sortBy) {
    var visibleMembers = document.querySelector('.js-row--grade-cards').children; // with [] coppied like this: [...visibleMembers] , it doesn't work in IE

    var sortedMembers = Array.from(visibleMembers);

    switch (sortBy) {
      case 'last-a-z':
        sortedMembers.sort(function (a, b) {
          return a.getAttribute('data-lname') < b.getAttribute('data-lname') ? -1 : 1;
        });
        break;

      case 'first-a-z':
        sortedMembers.sort(function (a, b) {
          return a.getAttribute('data-fname') < b.getAttribute('data-fname') ? -1 : 1;
        });
        break;

      default:
        break;
    }

    ;
    $CARDS_WRAPPER.innerHTML = '';
    sortedMembers.forEach(function ($sortedCard) {
      $CARDS_WRAPPER.appendChild($sortedCard);
      $sortedCard.addEventListener('click', function (e) {
        e.preventDefault();
        replaceModalContents($sortedCard, 'modal-staff');
        openModal('modal-staff');
      });
    });
  }; // handle sorting


  var _loop = function _loop(x) {
    $STAFF_SORT_OPTIONS[x].addEventListener('click', function (e) {
      $STAFF_SORT_OPTIONS.forEach(function (sortoption) {
        sortoption.classList.remove('js-active');
      });
      e.preventDefault();
      var that = $STAFF_SORT_OPTIONS[x];
      that.classList.add('js-active');
      $STAFF_SORT_TOGGLE.textContent = that.textContent;
      currentlyActiveSortOption = that.getAttribute('data-staff-sort'); // call sort fn

      staffSort(currentlyActiveSortOption);
    });
  };

  for (var x = 0; x < $STAFF_SORT_OPTIONS.length; x++) {
    _loop(x);
  }
}; // filter and sort fn
// Directors modals (on about us and board of directors pages)
// replaceDirectorModalContents


var directorsModals = function directorsModals() {
  var $directorsCards = document.querySelectorAll('.director-card');
  $directorsCards.forEach(function ($card) {
    $card.querySelector('.avatar-card').addEventListener('click', function (e) {
      e.preventDefault();
      replaceModalContents($card, 'modal-director');
      openModal('modal-director');
    });
  });
}; // end Directors modals fn
// Paths modals


var pathsModals = function pathsModals() {
  var $pathCards = document.querySelectorAll('.path-card');
  $pathCards.forEach(function ($card) {
    $card.addEventListener('click', function (e) {
      e.preventDefault();
      replaceModalContents($card, 'modal-path');
      openModal('modal-path');
    });
  });
}; // end Paths modals
// Activity modals


var activityModals = function activityModals() {
  var $activityCards = document.querySelectorAll('.activity-card');
  $activityCards.forEach(function ($card) {
    $card.addEventListener('click', function (e) {
      e.preventDefault();
      replaceModalContents($card, 'modal-activity');
      openModal('modal-activity');
    });
  });
}; // end Activity modals
// PTO officers modals


var officersModals = function officersModals() {
  var $officersCards = document.querySelectorAll('.officer-card');
  $officersCards.forEach(function ($card) {
    $card.addEventListener('click', function (e) {
      e.preventDefault();
      replaceModalContents($card, 'modal-officer');
      openModal('modal-officer');
    });
  });
}; // end PTO officers modals
// tabs fix


var tabsFix = function tabsFix() {
  var $tabsWrapper = document.querySelector('.tabs');

  var tabTriggers = _toConsumableArray($tabsWrapper.querySelectorAll('.nav-item'));

  var tabPanes = _toConsumableArray($tabsWrapper.querySelectorAll('.tab-pane'));

  var _loop2 = function _loop2(i) {
    tabTriggers[i].addEventListener('click', function (e) {
      e.preventDefault();
      tabTriggers.forEach(function (t) {
        t.classList.remove('active');
      });
      tabPanes.forEach(function (p) {
        p.classList.remove('active');
      });
      tabTriggers[i].classList.add('active');
      tabPanes[i].classList.add('active', 'show');
    });
  };

  for (var i = 0; i < tabTriggers.length; i++) {
    _loop2(i);
  }
}; // play video fn


var playVideo = function playVideo() {
  var iframeWrapID = 'video-wrapper';
  var isVimeo = document.getElementById('is-vimeo').value;
  var videoEmbedID = document.getElementById('videoID').value;
  var $btn = document.querySelector('.play'); // load api scripts async

  var firstScriptTag = document.getElementsByTagName('script')[0];
  var tag = document.createElement('script');
  var player; // let playVideo;
  // if vimeo

  if (isVimeo === 'yes') {
    tag.src = "https://player.vimeo.com/api/player.js";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    var videoOptions = {
      id: videoEmbedID
    }; // sometimes script is too fast (Vimeo api is not loaded, so we are checking via interval when it's loaded)

    var vimeoInterval = setInterval(function () {
      if (window.Vimeo !== undefined) {
        clearInterval(vimeoInterval);
        player = new Vimeo.Player(iframeWrapID, videoOptions);
      }
    }, 300);
  } else {
    // youtube
    tag.src = "https://www.youtube.com/iframe_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = function () {
      player = new YT.Player('placeholderforyt', {
        videoId: videoEmbedID,
        playerVars: {
          rel: 0,
          showinfo: 0,
          ecver: 0
        }
      });
    };
  }

  $btn.addEventListener('click', function (e) {
    $btn.style.display = 'none';
    e.preventDefault();

    if (isVimeo === 'yes') {
      player.play();
    } else {
      player.playVideo();
    }
  });
}; // doc ready


document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll('.play').length) {
    playVideo();
  }

  if (document.querySelectorAll('.staff-sort--option').length) {
    // Staff filter
    staffFilterAndSort();
  }

  if (document.querySelectorAll('.tabs').length) {
    tabsFix();
  }

  if (document.querySelectorAll('.director-card').length) {
    directorsModals();
  } // path modals on academics page


  if (document.getElementById('modal-path') !== null) {
    pathsModals();
  } // Activity modals on academics page


  if (document.getElementById('modal-activity') !== null) {
    activityModals();
  } // PTO officers modals


  if (document.getElementById('modal-officer') !== null) {
    officersModals();
  }

  if (document.querySelectorAll('.partners-carousel').length) {
    var swiper = new Swiper('.partners-carousel', {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      breakpoints: {
        575: {
          slidesPerView: 3
        },
        470: {
          slidesPerView: 2
        },
        340: {
          slidesPerView: 1
        }
      }
    });
  }

  if (document.querySelectorAll('.awards-carousel').length) {
    var swiper = new Swiper('.awards-carousel', {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      breakpoints: {
        991: {
          slidesPerView: 3
        },
        740: {
          slidesPerView: 2
        }
      }
    });
  }

  if (document.querySelectorAll('.slider').length) {
    var sliders = _toConsumableArray(document.querySelectorAll('.slider'));

    sliders.forEach(function (singleSlider, index) {
      var swiper = new Swiper(".slider-".concat(index), {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination-".concat(index),
          clickable: true
        }
      });
    });
  }

  if (document.querySelector('.slider--hero')) {
    var _swiper = new Swiper('.slider--hero', {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      draggable: false,
      speed: 1300,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    });
  }

  var $header = document.querySelector(".header"),
      $logo = $header.querySelector(".header .navbar-brand");
  var SCROLL_OFFSET = 20;
  window.addEventListener("scroll", function () {
    if (document.documentElement.scrollTop > SCROLL_OFFSET || window.scrollY > SCROLL_OFFSET) {
      $header.classList.add("scrolling");
      $logo.classList.add("scrolling");
    } else {
      $header.classList.remove("scrolling");
      $logo.classList.remove("scrolling");
    }
  }); // add target blank for each external link

  function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");
    var a = document.getElementsByTagName('a'); // then, grab every link on the page

    for (var i = 0; i < a.length; i++) {
      var href = a[i].host; // set the host of each link

      if (!internal.test(href)) {
        // make sure the href doesn't contain current site's host
        a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
      }
    }
  }

  ;
  targetBlank(); // open homepage bio modal for Dr Willis

  if (document.body.classList.contains('home')) {
    document.querySelector('[data-modal="modal-biography"]').addEventListener('click', function (e) {
      e.preventDefault();
      openModal('modal-biography');
    });
  }
}); // end doc ready

},{}]},{},[1]);
